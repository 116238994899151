<template>
  <div style="width: 100%">
    <CurveSection color="primary" fullWidth="{true}">
      <div class="cnai-supporters">
        <img
          src="../assets/menu_logo.png"
          alt="menu logo"
          class="menu-logo"
          @click="
            () => {
              goTo('Home');
            }
          "
        />
        <h2
          class="title"
          data-content="Application & Program Process"
          v-html="'Application & Program Process'"
        ></h2>
        <p class="mb-6">
          Cnai Accelerator is funded and supported by a range of partners that
          are dedicated to creating equitable, productive entrepreneurship
          ecosystems:
        </p>
        <h2
          class="title mb-3"
          data-content="Cohort 1 (2022- 2023)"
          v-html="'Cohort 1 (2022- 2023)'"
        ></h2>

        <div class="cohort1-grid mb-6">
          <img
            class=""
            src="../assets/cs-7.png"
            alt="cs-1"
            style="width: 20rem"
          />
          <img
            class=""
            style="width: 7rem; height: 8rem"
            src="../assets/cs-1.png"
            alt="cs-1"
          />
        </div>
        <h2
          class="title mb-3"
          data-content="Cohort 2 (2023- 2024)"
          v-html="'Cohort 2 (2023- 2024)'"
        ></h2>

        <div class="cohort2-grid mb-6">
          <img
            class=""
            style="width: 9rem; height: 8rem"
            src="../assets/cs-5.png"
            alt="cs-5"
          />
          <img
            class=""
            src="../assets/cs-2.png"
            alt="cs-2"
            style="width: 15rem"
          />
          <img
            class=""
            src="../assets/cs-7.png"
            alt="cs-6"
            style="width: 20rem"
          />
          <img
            class=""
            style="width: 7rem; height: 8rem"
            src="../assets/cs-1.png"
            alt="cs-1"
          />
          <img class="aussi-aid" src="../assets/cs-4.png" alt="cs-4" />
        </div>

        <h2
          class="title mb-3"
          data-content="Cnai23 - Post Program Support"
          v-html="'Cnai23 - Post Program Support'"
        ></h2>
        <div class="post-program-support">
          <img
            class="pps-logo"
            src="../assets/feed_the_future.png"
            alt="cs-5"
          />
          <img class="pps-logo" src="../assets/usaid.png" alt="cs-5" />
        </div>
      </div>
    </CurveSection>
    <Footer color="white" />
  </div>
</template>

<script>
// import HeroSection from "@/components/HeroSection.vue";
import CurveSection from "@/components/CurveSection.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Cnai Supporters",
  components: {
    CurveSection,
    Footer,
    // HeroSection,
  },
  created() {
    if (this.$store.state.isMenuOpen) this.$store.commit("toggleMenuOpen");
    document.title = this.$router.currentRoute.value.meta.title;
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/definations.scss";
@import "@/scss/cnaiSupporters.scss";
</style>
